import styled from "@emotion/styled";
import * as React from "react";

const justifyMap = {
  start: "flex-start",
  end: "flex-end",
  "space-between": "space-between",
  "space-around": "space-around",
  center: "center",
};

const alignMap = {
  start: "flex-start",
  end: "flex-end",
  "space-between": "space-between",
  "space-around": "space-around",
  stretch: "stretch",
  center: "center",
};

const justifyContent = ({
  justify = "center",
}: {
  justify?: keyof typeof justifyMap;
}) => justifyMap[justify];

const alignItems = ({ align = "center" }: { align?: keyof typeof alignMap }) =>
  alignMap[align];

export interface BoxProps {
  wrap?: boolean;
  grow?: boolean;
  inline?: boolean;
  height?: string;
  justify?: keyof typeof justifyMap;
  align?: keyof typeof alignMap;
}

export const Box = styled.div<BoxProps>`
  display: flex;
  justify-content: ${(p) => justifyContent(p)};
  align-items: ${(p) => alignItems(p)};
  flex-wrap: ${(p) => (p.wrap ? "wrap" : "no-wrap")};
  flex-grow: ${(p) => (p.grow ? 1 : 0)};
  width: ${(p) => (p.inline ? "auto" : "100%")};
  height: ${(p) => p.height || "100%"};
  position: relative;
`;

export const Row = styled(Box)`
  flex-direction: row;
`;

export const Column = styled(Box)`
  flex-direction: column;
`;

export const Columns = styled.div`
  margin: 0 auto;
  padding: 0;
  border: none;
  width: 980px;
  display: flex;
  position: relative;
`;

export const Container = styled(Box)`
  & .${(p) => (p.className ? p.className : "")} {
    max-width: 980px;
    margin: 0 auto;
  }
`;

interface IImage {
  srcSet?: string;
  src?: string;
}

const getSrcSet = (data: IImage): string => data.srcSet;
const getSrc = (data: IImage): string => data.src;

export const Image = ({
  fixed,
  fluid,
  ...props
}: {
  fixed: IImage;
  fluid: IImage;
}) => (
  <img
    srcSet={getSrcSet(fixed || fluid)}
    {...props}
    src={getSrc(fixed || fluid)}
  />
);

export default Box;
