import * as React from "react";
import axios from "axios";
import { Trans } from "gatsby-plugin-react-i18next";
import { api } from "../../config";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Column } from "../../components/primitives";
import Spinner from "../../components/spinner/spinner";
import FormStatus from "../../components/form/formStatus";
import Card from "./card";
import { BrandButton } from "../../lib/components/Button";
import { getAccessToken, isLoggedIn } from "../../lib/auth";
import Stripe from "../../components/stripe/stripe";
import { navigateTo } from "../../lib/UrlUtils";

class Payment extends React.Component {
  state = {
    error: null,
    success: null,
    loading: null,
  };

  attachForm = (node) => {
    this._form = node;
    // this._form = ReactDOM.findDOMNode(node);
  };

  onSubmit = ({ token, error }) => {
    const _self = this;
    if (error)
      return this.setState({ loading: false, error: error.message }, () => {
        _self.props.updateStatus({
          cancelSuccess: "",
          cancelFailure: error.message,
        });
      });
    this.setState({ loading: true });

    axios({
      method: "post",
      url: `${api.base}${api.updatePaymentMethod}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: {
        token: token.id,
      },
    }).then((res) => {
      if (res.data.error) {
        return this.setState(
          {
            error: <Trans>paymentMethodFailed</Trans>,
            loading: false,
          },
          () => {
            _self.props.updateStatus({
              cancelSuccess: <Trans>paymentMethodUpdateSuccess</Trans>,
              cancelFailure: <Trans>paymentMethodFailed</Trans>,
            });
          }
        );
      }

      this.setState(
        {
          success: "Payment method updated successfully",
          error: null,
          loading: false,
        },
        () => {
          _self.props.updateStatus({
            cancelSuccess: "Payment method updated successfully",
            cancelFailure: "",
          });
        }
      );
    });
  };

  onError = (err) => {
    this.setState({
      success: "Payment Method Updated",
      error: "null",
      loading: false,
    });
  };

  componentDidMount() {
    if (!isLoggedIn()) {
      navigateTo("/login");
    }
  }

  render() {
    const { error, success } = this.state;
    return (
      <Card titleText={"Payment Method"} className="wide_card">
        <Column
          css={css`
            width: 348px;
            height: 150px;
            border-radius: 5px;
            border: 1px solid #cbcbcb;
            background-color: #f4f4f4;
            @media (min-width: 351px) and (max-width: 599px) {
              margin-bottom: 24px;
              width: 260px;
            }
            @media (max-width: 350px) {
              width: 220px;
            }
            @media (max-width: 1050px) {
              margin-bottom: 24px;
            }
          `}
        >
          <Spinner active={this.state.loading} />

          <Stripe
            formStatus={<FormStatus error={error} success={success} />}
            button={"Change Payment Details"}
            onSubmit={this.onSubmit}
            onError={this.onError}
            attachForm={this.attachForm}
          />
        </Column>
        <BrandButton
          className={"header__nav-button button-hover"}
          css={css`
            margin-top: 16px;
            min-width: 250px;
          `}
          onClick={(e) => {
            this._form.handleSubmit(e);
          }}
        >
          {"Update Payment"}
        </BrandButton>
      </Card>
    );
  }
}

export default Payment;
