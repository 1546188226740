import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

export default ({ error, success }) => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      margin: 15px 0;
      height: 20px;
      color: ${error ? "red" : "green"};
    `}
  >
    <span>
      {error}
      {success}
    </span>
  </div>
);
