import * as React from "react";
import axios from "axios";
import { api } from "../../config";
import { graphql } from "gatsby";
import { format } from "date-fns";

import ConfirmButton from "../../components/unsubscribeButton/confirmButton";
import Card from "./card";
import AccountLayout from "./account-layout";
import Payment from "./payment-page";
import MealplanIcon from "./mealplan-icon";
import FitplanIcon from "./fitplan-icon";
import Member from "../../components/Account/MembershipPanel";
import OneTimeProducts from "../../components/Account/OneTimeProducts";
import Mealplan from "./mealplan-page";
import {
  isLoggedIn,
  getUser,
  getAccessToken,
  getUserData,
} from "../../lib/auth";
import Layout from "../../components/layout/layout";
import { navigateTo } from "../../lib/UrlUtils";
import { withTranslation } from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

class Subscription extends React.Component {
  state = {
    user: getUser(),
    cancelSuccess: null,
    cancelFailure: null,
    showConfirm: false,
    cancelSuccess: "",
    cancelFailure: "",
    confirm: {
      title: "",
      info: "",
      question: "",
      cancelButton: "",
      confirmButton: "",
    },
  };

  showConfirm = (e) => {
    this.setState({ confirm: e, showConfirm: !this.state.showConfirm });
  };

  updateStatus = (e) => {
    this.setState(
      {
        cancelSuccess: e.cancelSuccess,
        cancelFailure: e.cancelFailure,
      },
      // TODO: check if we need hide toast
      () => {
        setTimeout(
          function () {
            this.setState({
              cancelSuccess: "",
              cancelFailure: "",
            });
          }.bind(this),
          3000
        );
      }
    );
  };

  handleCancelClick = (e) => {
    const { t } = this.props;
    if (this.state.user.paymentStoreType == 3) {
      axios({
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
        url: `${api.base}${api.cancelStripe}`,
      })
        .then((res) => {
          if (res.data.result) {
            getUserData(getAccessToken()).then(() => {
              this.setState({
                cancelSuccess: t("cancelSuccess"),
                cancelFailure: "",
                showConfirm: false,
                user: Object.assign({}, getUser(), {
                  cancelAtPeriodEnd: true,
                }),
              });
            });
          }
        })
        .catch(() => {
          this.setState({
            showConfirm: false,
            cancelSuccess: "",
            cancelFailure: t("cancelFailure"),
          });
        });
    }
  };

  render() {
    const { header, ribbon, footer } = this.props.data;
    const { t } = this.props;
    const account = {
      member: t("member"),
      nonMember: t("nonMember"),
      nonMemberMessage: t("nonMemberMessage"),
      joinFitplan: t("joinFitplan"),
      joinFitplanMessage: t("joinFitplanMessage"),
      overview: t("overview"),
      mealMember: t("mealMember"),
      nonMealMember: t("nonMealMember"),
      joinMeal: t("joinMeal"),
      joinMealMessage: t("joinMealMessage"),
      cancelSuccess: t("cancelSuccess"),
      cancelFailure: t("cancelFailure"),
      updatePaymentMethod: t("updatePaymentMethod"),
      updatePaymentFail: t("updatePaymentFail"),
      updatePaymentSuccess: t("updatePaymentSuccess"),
      paymentMethod: t("paymentMethod"),
      subscriptionTitle: t("subscriptionTitle"),
      subscriptionCancelButton: {
        buttonText: t("subscriptionCancelButtonbuttonText"),
        unsubscribeAppleCode: t("subscriptionCancelButtonunsubscribeAppleCode"),
        unsubscribeGoogleCode: t(
          "subscriptionCancelButtonunsubscribeGoogleCode"
        ),
        title: t("subscriptionCancelButtontitle"),
        info: t("subscriptionCancelButtoninfo"),
        question: t("subscriptionCancelButtonquestion"),
        cancelButton: t("subscriptionCancelButtoncancelButton"),
        confirmButton: t("subscriptionCancelButtonconfirmButton"),
      },
      changePaymentDetails: t("changePaymentDetails"),
      enterCreditCard: t("enterCreditCard"),
    };

    // this.setState({ cancelSuccess: account.cancelSuccess, cancelFailure: account.cancelFailure });

    let hideButton = false;
    if (
      this.state.user &&
      this.state.user.giftCards &&
      this.state.user.giftCards.length > 0
    ) {
      hideButton = true;
    }
    if (
      this.state.user &&
      this.state.user.oneTimeProducts &&
      this.state.user.oneTimeProducts.length > 0
    ) {
      hideButton = true;
    }

    if (!isLoggedIn()) {
      return navigateTo("/login");
    }

    return (
      <Layout
        header={header}
        ribbon={ribbon}
        footer={footer}
        hasBanner="white-banner"
      >
        <AccountLayout currentPage="/account/subscription">
          {this.state.showConfirm ? (
            <Card className="wide_card">
              <ConfirmButton
                onCancel={this.showConfirm}
                onConfirm={this.handleCancelClick}
                cancelText={this.state.confirm.cancelText}
                confirmText={this.state.confirm.confirmText}
                title={this.state.confirm.title}
                url={
                  this.state.user.paymentStoreType === 3
                    ? ""
                    : this.state.user.paymentStoreType === 2
                    ? account.subscriptionCancelButton.unsubscribeGoogleCode
                    : account.subscriptionCancelButton.unsubscribeAppleCode
                }
                info={
                  this.state.confirm.info +
                  " " +
                  (this.state.user.displayExpirationTimeStamp
                    ? format(
                        new Date(this.state.user.displayExpirationTimeStamp),
                        "MMMM do, yyyy"
                      )
                    : "")
                }
                question={this.state.confirm.question}
                cancelButton={account.subscriptionCancelButton.cancelButton}
                confirmButton={account.subscriptionCancelButton.confirmButton}
              />
            </Card>
          ) : (
            <>
              <Member
                titleText="Fitplan"
                SVGIcon={<FitplanIcon width="40px" />}
                user={this.state.user}
                profile={account}
                handleCancelClick={this.handleCancelClick}
                onConfirm={this.handleCancelClick}
                hideButton={hideButton}
                showConfirm={this.showConfirm}
              />
              <OneTimeProducts
                titleText="One Time Products"
                oneTimeProducts={this.state.user?.oneTimeProducts}
              />
              {false && (
                <Mealplan
                  titleText="Mealplan"
                  SVGIcon={<MealplanIcon width={24} viewBox="0 0 24 24" />}
                  user={this.state.user}
                  profile={account}
                  handleCancelClick={this.handleCancelClick}
                  onConfirm={this.handleCancelClick}
                  showConfirm={this.showConfirm}
                />
              )}
              {this.state.user.paymentStoreType !== 0 &&
              this.state.user.paymentExpirationTimestamp !== 0 ? (
                <Payment profile={account} updateStatus={this.updateStatus} />
              ) : (
                ""
              )}
            </>
          )}
        </AccountLayout>
      </Layout>
    );
  }
}

export default withTranslation()(Subscription);
