import * as React from "react";
import Proptypes from "prop-types";
import { CardElement } from "react-stripe-elements";
import "./card.scss";

const style = {
  base: {
    fontSize: "15px",
    lineHeight: "24px",
    display: "grid",
    gridTemplateColumns: "70% 30%",
    margin: "10px",
  },
};

const CardSection = ({ label, onReady, onError }) => (
  <div className="cardSection">
    {label && <span>{label}</span>}

    <CardElement style={style} onReady={onReady} onError={onError} />
  </div>
);

CardSection.propTypes = {
  label: Proptypes.string,
  onReady: Proptypes.func,
  onError: Proptypes.func,
};

CardSection.defaultProps = {
  label: "",
  onReady: () => null,
  onError: () => null,
};

export default CardSection;
