import * as React from "react";
import { Link } from "../../lib/components/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faTimesCircle from "@fortawesome/fontawesome-free-regular/faTimesCircle";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { format } from "date-fns";

import UnsubscribeButton from "../unsubscribeButton/unsubscribeButton";

import Card from "../../pages/account/card";
import { IUser } from "@fitplan/context/lib-es/auth/types";
import { isLoggedIn } from "@fitplan/context/lib/auth/storage";
import { navigateTo } from "../../lib/UrlUtils";
import { useTranslation } from "gatsby-plugin-react-i18next";

const reg = new RegExp(/{(\$\d+)}/);
const formatMentionText = (text: string, values: string[], regex: RegExp) => {
  if (!values.length) return text;
  return (
    <div>
      {text.split(regex).reduce((prev, current, i) => {
        if (!i) return [current];
        if (current.slice(0, 1) == "$") {
          return prev.concat(
            <strong key={i + current}>
              {values[Number(current.slice(1)) - 1]}
            </strong>
          );
        } else {
          return prev.concat(current);
        }
      }, [])}
    </div>
  );
};

interface Props {
  handleCancelClick: () => void;
  showConfirm: () => void;
  user: IUser;
  hideButton: boolean;
  titleText: string;
  SVGIcon: React.ReactNode;
  profile: {
    currentSubscription: string;
    joinFitplan: string;
    subscriptionCancelButton: {
      title: string;
      info: string;
      question: string;
      cancelButton: string;
      confirmButton: string;
    };
    member: string;
    nonMember: string;
    joinFitplanMessage: string;
  };
}

const Member: React.FunctionComponent<Props> = ({
  profile,
  user,
  showConfirm,
  handleCancelClick,
  titleText,
  hideButton,
  SVGIcon,
}) => {
  const { t } = useTranslation();
  if (!profile || !user) return;
  const isMember = user.paymentStoreType !== 0 && user.paymentExpirationTimestamp !== 0;
  // TODO: leave const text = profile.currentSubscription when data will be awaible
  const text = t("currentSubscription");
  const expirationDate = format(new Date(user.displayExpirationTimeStamp), "MMMM do, yyyy");
  // .substr(0, profile.currentSubscription.indexOf("{$3}") + 4);
  // "Your {$1} will automatically renew on {$2}{$3} and you'll be charged {$4}";
  const values = [
    "Subscription",
    "",
    user.displayExpirationTimeStamp
      ? format(new Date(user.displayExpirationTimeStamp), "MMMM do, yyyy")
      : "",
    "",
  ];

  if (!isLoggedIn) {
    navigateTo("/login");
  }

  const giftCards = user.giftCards;
  const typeOfSubscription = giftCards.find(giftCard => parseInt(giftCard.expirationDate) === user.displayExpirationTimeStamp);
  return (
    <Card
      titleText={titleText}
      className="wide_card"
      SVGIcon={SVGIcon}
      rightNode={
        <div
          css={css`
            font-family: Barlow;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
          `}
        >
          {isMember ? (
            !hideButton ? (
              <span className="mt-4 md:mt-0">
                <UnsubscribeButton
                  user={user}
                  confirm={{
                    title: profile.subscriptionCancelButton.title,
                    info: profile.subscriptionCancelButton.info,
                    question: profile.subscriptionCancelButton.question,
                    cancelButton: profile.subscriptionCancelButton.cancelButton,
                    confirmButton:
                      profile.subscriptionCancelButton.confirmButton,
                    handleCancelClick: handleCancelClick,
                  }}
                  onClick={showConfirm}
                />
              </span>
            ) : (
              <div />
            )
          ) : (
            <Link to="/subscribe">
              <button type="submit" className="subscrip-button">
                {t("joinFitplan")}
              </button>
            </Link>
          )}
        </div>
      }
    >
      {/*
				<Icon type="faCheckCircle" />
				*/}
      {isMember ? (
        <h2 className="fitplan-member -ml-2 flex flex-no-wrap items-center">
          <svg
            viewBox="0 0 42 38"
            css={css`
              width: 26px;
              height: 24px;
            `}
          >
            <g
              fill="none"
              fillRule="evenodd"
              stroke="#12B587"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path d="M36.65 16.17c.14.899.212 1.823.212 2.761 0 9.903-8.028 17.931-17.931 17.931S1 28.834 1 18.931 9.028 1 18.931 1c2.985 0 5.799.728 8.276 2.02" />
              <path d="M10.655 14.793l8.276 8.276L41 1" />
            </g>
          </svg>
          <span className="ml-4">{t("member")}</span>
        </h2>
      ) : (
        <div
          css={css`
            background: #fff;
          `}
        >
          <h2 className="fitplan-nonmember">
            <FontAwesomeIcon
              icon={faTimesCircle as any}
              css={css`
                width: 42px;
                height: 38px;
                margin: 24px 16px -10px 0px;
              `}
            />{" "}
            {t("nonMember")}
          </h2>
        </div>
      )}

      {isMember ? (
        <div className="subscrip-content-block">
          {typeOfSubscription ? t("subscriptionWillExpire") + `${expirationDate}` : user.cancelAtPeriodEnd // dont @ me for this please
            ? [
              "Your ",
              <strong>subscription</strong>,
              " has been cancelled and will expire on ",
              <strong>{`${values[2]}`}</strong>,
            ]
            : formatMentionText(text, values, reg)}
        </div>
      ) : (
        <div className="subscrip-content-block">{t("nonMemberMessage")}</div>
      )}
    </Card>
  );
};

export default Member;
