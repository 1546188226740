import React, { Fragment } from "react";
import { format } from "date-fns";

import Card from "../../pages/account/card";

const OneTimeProducts = ({ titleText, oneTimeProducts }) => {
  if (!oneTimeProducts.length) {
    return null;
  }

  return (
    <Card className="wide_card">
      {oneTimeProducts.map((product) => (
        <Fragment key={product.id}>
          <h2 className="fitplan-member -ml-2 flex flex-no-wrap items-center">
            <svg
              viewBox="0 0 42 38"
              style={{
                width: "26px",
                height: "24px",
              }}
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="#12B587"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path d="M36.65 16.17c.14.899.212 1.823.212 2.761 0 9.903-8.028 17.931-17.931 17.931S1 28.834 1 18.931 9.028 1 18.931 1c2.985 0 5.799.728 8.276 2.02" />
                <path d="M10.655 14.793l8.276 8.276L41 1" />
              </g>
            </svg>
            <span className="ml-4">{product.name}</span>
          </h2>
          <div className="subscrip-content-block">
            <p>
              Purchase Date:{" "}
              <strong>
                {format(
                  new Date(product.datePurchased * 1000),
                  "MMMM do, yyyy"
                )}
              </strong>
            </p>
            {/* <p>
              Expiration Date:{" "}
              <strong>
                {format(new Date(product.endDate), "MMMM do, yyyy")}
              </strong>
            </p> */}
          </div>
        </Fragment>
      ))}
    </Card>
  );
};

export default OneTimeProducts;
