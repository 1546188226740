import * as React from "react";
import Proptypes from "prop-types";
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/react";

const steps = [
  [29, 53],
  [18, 50],
  [9, 41],
  [6, 29],
  [9, 18],
  [18, 9],
  [29, 6],
  [41, 8],
  [50, 18],
  [53, 29],
  [50, 41],
  [41, 50],
];

const spinAnimation = keyframes`
  0%,
  80%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.5);
  }
`;

const Spinner = ({ active, color }) =>
  active ? (
    <div
      css={css`
        display: inline-block;
        position: absolute;
        width: 64px;
        height: 64px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        div {
          position: absolute;
          width: 5px;
          height: 5px;
          background: ${color};
          border-radius: 50%;
          animation: ${spinAnimation} 1.2s linear infinite;
        }
        ${steps
          .map(
            (step, i) =>
              `.sk-circle${i + 1} {
              animation-delay: ${0 - (i ? i / 10 : 0)}s;
              top: ${step[0]}px;
              left: ${step[1]}px;
            }`
          )
          .join("\n")};
      `}
    >
      {steps.map((step, i) => (
        <div
          key={`sk-circle${step.join("")}`}
          className={`sk-circle${i + 1}`}
        />
      ))}
    </div>
  ) : null;

Spinner.propTypes = {
  active: Proptypes.bool,
  color: Proptypes.string,
};

Spinner.defaultProps = {
  active: false,
  color: "#000",
};

export default Spinner;
