import styled from "@emotion/styled";

export interface Props {
  padding?: string;
  margin?: string;
  height?: string;
  width?: string;
  color?: string;
  fontSize?: string;
  boxShadow?: string;
  marginTop?: string;
  marginBottom?: string;
}

export const BrandButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.padding || "0.5em 1em"};
  margin: ${(props) => props.margin || "0"};
  height: ${(props) => props.height || "auto"};
  min-width: ${(props) => props.width || "auto"};
  border-radius: 100px;
  border: 0;
  cursor: pointer;
  text-align: center;
  background-image: linear-gradient(78deg, #12b587, #29db57);
  font-family: "Barlow", sans-serif;
  color: ${(props) => props.color || "white"};
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 0px;
  font-size: ${(props) => props.fontSize || "1em"};
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal;
  box-shadow: ${(props) => props.boxShadow || "none"};

  box-sizing: border-box;

  :hover,
  :focus,
  :active {
    background-image: linear-gradient(260deg, #12b587, #29db57);
    box-shadow: none;
  }

  :disabled {
    cursor: default;
    background: #ccc;
  }
`;

export const BrandLink = styled.a<Props>`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.padding || "0.5em 1em"};
  margin: ${(props) => props.margin || "0"};
  height: ${(props) => props.height || "auto"};
  min-width: ${(props) => props.width || "auto"};
  border-radius: 100px;
  border: 0;
  cursor: pointer;
  text-align: center;
  background-image: linear-gradient(78deg, #12b587, #29db57);
  font-family: "Barlow", sans-serif;
  color: ${(props) => props.color || "white"};
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 0px;
  font-size: ${(props) => props.fontSize || "1em"};
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal;
  box-shadow: ${(props) => props.boxShadow || "none"};

  box-sizing: border-box;

  :hover,
  :focus,
  :active {
    background-image: linear-gradient(260deg, #12b587, #29db57);
    box-shadow: none;
  }

  :disabled {
    cursor: default;
    background: #ccc;
  }
`;
